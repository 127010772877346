<template>
  <div class="bg-light-blue-theme py-3">
    <div
      v-if="user.role === 3"
      class="d-flex menu-text-blue justify-content-center"
    >
      <div
        v-for="(item, index) in staffList"
        :key="index"
        @click="goto(item.path)"
        class="px-3 pointer"
      >
        {{ item.name }}
      </div>
    </div>
    <div
      v-if="user.role === 1"
      class="d-flex menu-text-blue justify-content-center"
    >
      <div
        v-for="(item, index) in adminList"
        :key="index"
        @click="goto(item.path)"
        class="px-3 pointer"
      >
        {{ item.name }}
      </div>
    </div>
    <div
      v-if="user.role === 2"
      class="d-flex menu-text-blue justify-content-center"
    >
      <div
        v-for="(item, index) in sjrList"
        :key="index"
        @click="goto(item.path)"
        class="px-3 pointer"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      user: (state) => {
        return state.Auth.user;
      },
    }),
  },
  data() {
    return {
      staffList: [
        { name: "หน้าแรก", path: "/" },
        { name: "ผลการบันทึกข้อมูล", path: "result" },
        { name: "รายงาน", path: "report" },
        { name: "ส่งออกข้อมูล", path: "export-data-summary" },
      ],
      adminList: [
        { name: "หน้าแรก", path: "/" },
        { name: "ผลการบันทึกข้อมูล", path: "result" },
        { name: "รายงาน", path: "report" },
        { name: "ส่งออกข้อมูล", path: "export-data-summary" },
        { name: "Log Activity", path: "Logactivity" },
      ],
      sjrList: [
        { name: "หน้าแรก", path: "/" },
        { name: "ผลการบันทึกข้อมูล", path: "result" },
        { name: "รายงาน", path: "report" },
      ],
    };
  },
  methods: {
    goto(path) {
      this.$router.push({
        path: path,
      });
    },
  },
};
</script>

<style>
</style>
