import Service from "../../services/index";

const dynamic = {
    namespaced: true,
    state:{
        dynamic_checkbox_option : [],
        options_main : [],
        options_second : [],
        dynamic_checkbox : [],
        dynamic_checkbox_selected : [],
        trigger : '',
    },
    mutations:{
        GET_OPTION(state,res){
            state.dynamic_checkbox_option = res.data.data
            state.dynamic_checkbox_option.forEach(function (element) {
                element.disabled = false;
                element.checked = false;
            });
            state.options_main = [...new Map(res.data.data.map(item => [item.table_column, item])).values()]
            state.options_second = [...new Map(res.data.data.map(item => [item.form_th, item])).values()]
            state.dynamic_checkbox = [];
            state.dynamic_checkbox_selected = [];
        },
        SET_DATA(state,val){
            val.value ? state.dynamic_checkbox.push(val.name) :
                state.dynamic_checkbox.splice(state.dynamic_checkbox.indexOf(val.name), 1);
            state.dynamic_checkbox_selected = state.dynamic_checkbox_option.filter((i) => {
              return state.dynamic_checkbox.includes(i.table_column)
            })
        },
        CHANGE_DATA(state,val){
            state.dynamic_checkbox_option.forEach(function (ele) {
                ele.table_column == val ? ele.disabled = true:ele.disabled = false;
                ele.table_column == val ? ele.checked = false:'';
            });
            if (state.dynamic_checkbox.includes(val)){
                state.dynamic_checkbox.splice(state.dynamic_checkbox.indexOf(val), 1)
                state.dynamic_checkbox_selected = state.dynamic_checkbox_option.filter((i) => {
                    return state.dynamic_checkbox.includes(i.table_column)
                })
            }
            state.trigger = val;
        },
        CHANGE_CHECKED(state,val){
            state.dynamic_checkbox_option.forEach(function (ele) {
                ele.table_column == val ? ele.disabled = true:ele.disabled = false;
                ele.table_column == val ? ele.checked = false:'';
            });
        }
    },
    actions:{
        // eslint-disable-next-line no-unused-vars
        getOptionDynamic({commit},data){
            Service.Dynamic.getOptionDynamic(data).then(res=>{
                commit('GET_OPTION',res)
            });
        },
        setData({commit},item){
            commit('SET_DATA',item)
        },
        changeData({commit},val){
            commit('CHANGE_DATA',val)
        },
        changeChecked({commit},val){
            commit('CHANGE_CHECKED',val)
        },
        // eslint-disable-next-line no-unused-vars
        getGraph({commit},data){
            return Service.Dynamic.getGraph(data)
        },
        // eslint-disable-next-line no-unused-vars
        getExcel({commit},data){
            return Service.Dynamic.getExcel(data)
        }
    }
}

export default dynamic;
