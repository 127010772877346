import auth from "./api/AuthAPI";
import DropDown from "./api/DropDownAPI";
import MasterData from "./api/MasterDataAPI";
import Violence1 from "./api/Violence1API";
import Violence2 from "./api/Violence2API";
import Violence3 from "./api/Violence3API";
import Violence4 from "./api/Violence4API"
import Violence5 from "./api/Violence5API"
import Pregnant1 from "./api/Pregnant1API";
import Pregnant2 from "./api/Pregnant2API";
import Pregnant3 from "./api/Pregnant3API";
import Common from "./api/Common"
import Form from "./api/FormAPI"
import ReportSummary from "./api/ReportSummaryAPI"
import Dashboard from "./api/DashboardAPI";
import Dynamic from "./api/DynamicAPI";
import Datauser from "./api/Datauser";
export default {
    auth,
    DropDown,
    MasterData,
    Violence1,
    Violence2,
    Violence3,
    Violence4,
    Violence5,
    Pregnant1,
    Pregnant2,
    Pregnant3,
    Form,
    Common,
    ReportSummary,
    Dashboard,
    Dynamic,
    Datauser
}
