import Service from "../service";

const Violence4 = {
    saveForm(data){
        return Service.post('/violence/form4',data);
    },
    show(id){
        return Service.get('/violence/form4/'+id);
    },
};

export default Violence4;
