<template>
  <v-app>
    <div id="app">
      <Header></Header>
      <div class="main-layout">
        <router-view :key="$route.fullPath" />
      </div>
      <Footer></Footer>
    </div>
    <b-modal
      hide-header
      hide-footer
      size="lg"
      ref="login_modal"
      body-class="p-0"
    >
      <login-modal
        @regis="gotoRegister()"
        @login="Login"
        @forgot="gotoForgot()"
      >
      </login-modal>
    </b-modal>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import LoginModal from "./components/Modal/loginModal";
import Header from "./components/layout/Header.vue";
import Footer from "./components/layout/Footer.vue";
export default {
  name: "App",
  components: {
    LoginModal,
    Header,
    Footer,
  },
  data: () => ({
    //
  }),
  computed: {
    ...mapState({
      user: (state) => {
        return state.Auth.user;
      },
    }),
  },
  methods: {
    showLoading() {
      this.$loading.show({
        background: this.$swal.fire({
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        }),
        animation: true,
      });
    },
    hideLoading() {
      this.$loading.hide();
      this.$swal.hideLoading();
    },
    showLoginModal() {
      this.$refs.login_modal.show();
    },
    hideLoginModal() {
      this.$refs.login_modal.hide();
    },
    details() {
      this.$store.dispatch("Auth/details");
    },
    Login(val){
      this.showLoading()
      this.$store.dispatch("Auth/login", val).then(res=>{
        if(res.message === 'เข้าสู่ระบบสำเร็จ'){
          if (this.$route.path !== '/'){
            this.$swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'เข้าสู่ระบบสำเร็จ',
              showConfirmButton: false,
              timer: 1500
            })
            this.$router.push('/');
          }else{
            this.$swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'เข้าสู่ระบบสำเร็จ',
              showConfirmButton: false,
              timer: 1500
            })
            window.location.reload();
          }
        }else{
          this.hideLoginModal()
          alert(res.message)
          this.$swal.fire({
            icon: 'error',
            title: res.message,
          })
        }
      }).catch(()=>{
        this.hideLoginModal()
        this.$swal.fire({
          icon: 'error',
          title: 'กรุณาตรวจสอบข้อมูล อีเมลหรือรหัสผ่านไม่ถูกต้อง',
        })
      });
    },
    Logout() {
      this.$store.dispatch("Auth/logout").then(() => {
        if (this.$route.path !== "/") {
          this.$router.push("/");
        } else {
          window.location.reload();
        }
      });
    },
    gotoRegister() {
      this.hideLoginModal();
      if (this.$route.path !== "/register") {
        this.$router.push({ name: "register-form" });
      }
    },
    gotoForgot() {
      this.hideLoginModal();
      if (this.$route.path !== "/forgot-password") {
        this.$router.push({ name: "ForgotPassword" });
      }
    },
  },
};
</script>
<style>
#app {
  font-family: Prompt;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
