import Service from "../../services/index";

const pregnant = {
    namespaced: true,
    state: {
        form: null,
        form2: null,
        form3: null,
    },
    mutations: {
        SET_FORM1: (state, data) => {
            state.form = data
        },
        SET_FORM2: (state, data) => {
            state.form2 = data
        },
        SET_FORM3: (state, data) => {
            state.form3 = data
        },
    },
    actions: {
        showForm1({ commit }, id) {
            return Service.Pregnant1.show(id).then((response) => {
                commit("SET_FORM1", response.data);
                return response;
            })
        },
        showForm2({ commit }, id) {
            return Service.Pregnant2.show(id).then((response) => {
                commit("SET_FORM2", response.data);
                return response.data;
            })
        },
        showForm3({ commit }, id) {
            return Service.Pregnant3.show(id).then((response) => {
                commit("SET_FORM3", response.data);
                return response.data;
            })
        },
        saveForm({ commit }, data) {
            return Service.Pregnant1.saveForm(data).then((response) => {
                commit("SET_FORM1", response.data);
                return response;
            })
        },
        saveForm2({ commit }, data) {
            return Service.Pregnant2.saveForm(data).then((response) => {
                commit("SET_FORM2", response.data);
                return response;
            })
        },
        saveForm3({ commit }, data) {
            return Service.Pregnant3.saveForm(data).then((response) => {
                commit("SET_FORM3", response.data);
                return response;
            })
        },
    }
};

export default pregnant;
