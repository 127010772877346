<template>
    <div class="modal-oscc">
        <span style="font-size: 20px">เข้าสู่ระบบ</span>
        <b-row class="mt-4">
            <b-col cols="3"></b-col>
            <b-col>
                <b-input-group class="input-group-form-oscc">
                    <template #prepend>
                        <b-input-group-text class="input-prepend-oscc"><b-img :src="require('@/assets/icon/user.svg')"></b-img></b-input-group-text>
                    </template>
                    <b-form-input v-on:keyup.enter="login()" v-model="info.username" class="input-form-oscc" placeholder="อีเมลผู้ใช้งาน"></b-form-input>
                </b-input-group>
            </b-col>
            <b-col cols="3"></b-col>
        </b-row>
        <b-row class="mt-4">
            <b-col cols="3"></b-col>
            <b-col>
                <b-input-group class="input-group-form-oscc">
                    <template #prepend>
                        <b-input-group-text class="input-prepend-oscc">
                            <b-img :src="require('@/assets/icon/password.svg')"></b-img>
                        </b-input-group-text>
                    </template>
                    <b-form-input v-on:keyup.enter="login()" v-model="info.password" class="input-form-oscc" :type="passwordFieldType" placeholder="รหัสผ่าน"></b-form-input>
                    <template #append>
                        <b-input-group-text class="input-prepend-oscc" style="cursor:pointer;" @click="showHide">
                            <b-img width="20%" height="20%" :src="require('@/assets/icon/show_password.svg')"></b-img>
                        </b-input-group-text>
                    </template>
                </b-input-group>
            </b-col>
            <b-col cols="3"></b-col>
        </b-row>
        <b-row class="mt-4">
            <b-col cols="3"></b-col>
            <b-col>
                <b-button class="button-oscc w-100" @click="login()">เข้าสู่ระบบ</b-button>
            </b-col>
            <b-col cols="3"></b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col cols="3"></b-col>
            <b-col>
                <a class="a-oscc" @click="gotoRegister()">ลงทะเบียนเข้าใช้งาน</a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a class="a-oscc" @click="gotoForgot()">ลืมรหัสผ่าน ?</a>
            </b-col>
            <b-col cols="3"></b-col>
        </b-row>
        <b-row class="mt-2">
            <b-col cols="3"></b-col>
            <b-col>
                <a style="font-size: 14px">เฉพาะระบบรายงานช่วยเหลือเด็กและสตรี</a><br/>
                <a style="font-size: 12px">ระบบรายงานด้านข้อมูลบริหารเวชภัณฑ์ ระบบอื่น ๆ กรุณาติดต่อผู้ดูแลระบบ</a>
            </b-col>
            <b-col cols="3"></b-col>
        </b-row>
        <b-row>
            <b-col></b-col>
            <b-col>
            </b-col>
            <b-col cols="2" sm="3">
                <div>
                    <b-img width="100%" height="100%" :src="require('@/assets/icon/login_logo.png')"></b-img>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    export default {
        name: "login-modal"
        ,
        data: () => ({
            info : {
                username : '',
                password : '',
            },
            passwordFieldType : 'password'
        }),
        methods:{
            showHide(){
                this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
            },
            gotoRegister(){
                this.$emit("regis",true);
            },
            gotoForgot(){
                this.$emit("forgot",true);
            },
            login(){
                this.$emit("login", this.info);
            }
        }
    }
</script>

<style scoped>
    .input-form-oscc{
        border: transparent !important;
    }
    .input-form-oscc:focus {
        outline: none !important;
    }
    .input-group-form-oscc{
        background: #FFFFFF;
        border: 1px solid #4579DF;
        box-sizing: border-box;
        border-radius: 80px;
    }
    .input-prepend-oscc{
        background: transparent;
        border: transparent;
        margin-top: 2px;
    }
    .modal-oscc{
        font-family: Prompt;
        background: #EEF2FF;
        border-radius: 10px;
        text-align: center;
        padding-top: 3%;
    }
    .button-oscc{
        font-size: 18px;
        background: #4579DF;
        box-shadow: inset 0px 4px 12px rgba(200, 211, 249, 0.6);
        border-radius: 80px
    }
    .a-oscc{
        font-size: 14px;
        text-decoration: none;
        cursor: pointer;
    }
    .input-form-oscc::-webkit-input-placeholder { color: #94B8FF; }  /* WebKit, Blink, Edge */
    .input-form-oscc:-moz-placeholder { color: #94B8FF; }  /* Mozilla Firefox 4 to 18 */
    .input-form-oscc::-moz-placeholder { color: #94B8FF; }  /* Mozilla Firefox 19+ */
    .input-form-oscc:-ms-input-placeholder { color: #94B8FF; }  /* Internet Explorer 10-11 */
    .input-form-oscc::-ms-input-placeholder { color: #94B8FF; }  /* Microsoft Edge */
</style>
