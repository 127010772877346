import Service from "../service";
const url = 'pregnant/form1'
const Pregnant1 ={
    saveForm(data){
        return Service.post(url,data);
    },
    show(id){
        return Service.get(`${url}`,{
            params:{
                form_id:id
            }
        });
    },
}
export default Pregnant1