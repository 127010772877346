import Service from "../service";
const url = 'violence/form3'
const Violence3 ={
    saveForm(data){
        return Service.post(url,data);
    },
    show(id){
        return Service.get(`${url}`,{
            params:{
                form_id:id
            }
        });
    },
}
export default Violence3