import Service from "../service";

const Datauser = {
    get(data) {
        console.log(data)
        return  Service.get(`/data-user?limit=${data.limit}&page=${data.page}`,
            {
                params: {
                    data
                }
            });
    },
    getExcel(data) {
        // console.log(data)
        return Service.post("/person-report", {
            params: {
                data
            }
        });
    },
    getformExcel(data) {
        // console.log(data)
        return Service.post("/Excel-datauser", {
            params: {
                data
            }
        });
    }
}
export default Datauser;