import Service from "../service";
const url = 'violence/form2'
const Violence2 ={
    saveForm(data){
        return Service.post(url,data);
    },
    show(id){
        return Service.get(`${url}`,{
            params:{
                form_id:id
            }
        });
    },
}
export default Violence2