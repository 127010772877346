import Service from "../../services/index";

const reportSummary = {
    namespaced: true,
    state: {
        violence: {
            form1: null,
            form2: null,
            form3: null,
            form5: null,
        },
        pregnant: {
            form1: null,
            form2: null,
            form3: null,
        },
        age_range: null,
        info: null,
        count: 0
    },
    mutations: {
        SET_REPORT: (state, data) => {
            state.violence = data.violence
            state.pregnant = data.pregnant
            state.age_range = data.age_range
            state.info = data.info
            state.count = data.count
        },
        CLEAR_REPORT: (state) => {
            state.violence = {
                form1: null,
                form2: null,
                form3: null,
                form5: null,
            }
            state.pregnant = {
                form1: null,
                form2: null,
                form3: null,
            }
            state.age_range = null
            state.info = null
            state.count = 0
        },
    },
    actions: {
        clear({ commit }) {
            commit("CLEAR_REPORT");
        },
        show({ commit }, model) {
            return Service.ReportSummary.show(model).then((response) => {
                commit("SET_REPORT", response.data);
                return response.data;
            })
        }
    }
}

export default reportSummary;