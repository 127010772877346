import Service from "../service";
const url = 'form'
const Form ={
    list(model){
        return Service.get(url,{
            params:{
                page:model.page,
                oscc_no:model.oscc_no,
                row:model.row,
                hospital: model.hospital,
                hospital_code: model.hospital_code,
                first_name: model.first_name,
                status: model.status,
                id_number: model.id_number,
                last_name: model.last_name,
                patient_code: model.patient_code,
                start_event_date: model.start_event_date,
                end_event_date: model.end_event_date,
                start_hospital_date: model.start_hospital_date,
                end_hospital_date: model.end_hospital_date,
            }
        });
    },
    delete(id){
        return Service.delete(url+`/${id}`)
    },
    getOsccCode(){
        return Service.get('get-oscc-code')
    },
    getNoneId(){
        return Service.get('get-none-id')
    },
    exportForm(data){
        return Service.post('form-export',data)
    },
    exportFormResult(data){
        return Service.post('form-result-export',data)
    },
    exportReportSummary(data){
        return Service.post('report-summary-export',data)
    },
    accept_refer(data){
        return Service.post('accept-refer',data)
    }
}
export default Form
