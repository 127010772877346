import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Graph')
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('../views/About.vue')
    },
    {
        path: '/violenec',
        name: 'Violenec',
        component: () => import('../views/Violenec.vue')
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/Graph')
    },
    {
        path: '/register',
        name: 'register-form',
        component: () => import('../views/Register.vue')
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () => import('../views/ForgotPassword.vue')
    },
    {
        path: '/pregnant',
        name: 'Pregnant',
        component: () => import('../views/Pregnant.vue')
    },
    {
        path: '/result',
        name: 'Result',
        component: () => import('../views/Result.vue')
    },
    {
        path: '/report',
        name: 'Report',
        component: () => import('../views/Report.vue')
    },
    {
        path: '/report-dynamic',
        name: 'ReportDynamic',
        component: () => import('../views/DynamicGraph.vue')
    },
    {
        path: '/export-data-summary',
        name: 'ExportDataSummary',
        component: () => import('../views/ExportDataSummary.vue')
    },
    {
        path: '/export-data',
        name: 'ExportData',
        component: () => import('../views/ExportData.vue')
    },
    {
        path: '/reportresponsible',
        name: 'ReportResponsible',
        component: () => import('../views/ReportResponsible.vue')
    },
    {
        path: '/Logactivity',
        name: 'Logactivity',
        component: () => import('../views/Logactivity.vue')
    },
    {
        path: '/report-summary-question',
        name: 'ReportSummaryQuestion',
        component: () => import('../views/ReportSummaryQuestion.vue')
    },
    {
        path: '/report-summary-map',
        name: 'ReportSummaryMap',
        component: () => import('../views/ReportSummaryMap.vue')
    },
    {
        path: '/formexportdata',
        name: 'FormExportData',
        component: () => import('../views/FormExportData.vue')
    },
    {
        path: '/report-summary-recorder',
        name: 'ReportSummaryRecorder',
        component: () => import('../views/ReportSummaryRecorder.vue')
    },
    {
        path: '/reset-password/:token',
        name: 'ResetPassword',
        props: true,
        component: () => import('../views/ResetPassword.vue')
    },
    {
        path: '/details-user',
        name: 'DetailsUser',
        props: true,
        component: () => import('../views/Details.vue')
    }
]

const router = new VueRouter({
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
