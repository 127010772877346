import Service from "../../services/index";

const form = {
    namespaced: true,
    state: {
        formList: [],
        paginateFormList: {
            currentPage: 1,
            lastPage: 1,
            perPage: 1,
            total: 1,
        },
    },
    mutations: {
        SET_FORM_LIST: (state, data) => {
            state.formList = data
        },
        SET_PAGINATE: (state, data) => {
            state.paginateFormList.currentPage = data.current_page
            state.paginateFormList.lastPage = data.last_page
            state.paginateFormList.perPage = data.per_page
            state.paginateFormList.total = data.total
            state.paginateFormList.from = data.from
            state.paginateFormList.to = data.to
        },
    },
    actions: {
        list({ commit }, model) {
            return Service.Form.list(model).then((response) => {
                commit("SET_FORM_LIST", response.data.data);
                commit("SET_PAGINATE", response.data);
                return response.data;
            })
        },
        // eslint-disable-next-line no-unused-vars
        getOsccCode({ commit }) {
            return Service.Form.getOsccCode().then((response) => {
                return response.data;
            })
        },
        // eslint-disable-next-line no-unused-vars
        getNoneId({ commit }) {
            return Service.Form.getNoneId().then((response) => {
                return response.data;
            })
        },
        // eslint-disable-next-line no-unused-vars
        exportForm({ commit }, data) {
            return Service.Form.exportForm(data)
        },
        // eslint-disable-next-line no-unused-vars
        exportFormResult({ commit }, data) {
            return Service.Form.exportFormResult(data).then((response) => {
                return response;
            })
        },
        // eslint-disable-next-line no-unused-vars
        exportReportSummary({ commit }, data) {
            return Service.Form.exportReportSummary(data).then((response) => {
                return response;
            })
        },
        // eslint-disable-next-line no-unused-vars
        delete({ commit }, id) {
            return Service.Form.delete(id).then((response) => {
                return response;
            })
        },
        // eslint-disable-next-line no-unused-vars
        accept_refer({ commit }, data) {
            return Service.Form.accept_refer(data).then((response) => {
                return response;
            })
        }
    }
}

export default form;
