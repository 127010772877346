import Service from "../service";

const Dynamic ={
    getOptionDynamic(section){
        return Service.get("/get_option_dynamic?section="+section);
    },
    getGraph(data){
        return Service.post("/get_graph_dynamic",data);
    },
    getExcel(data){
        return Service.post("/dynamicExport",data);
    }
}
export default Dynamic
