import Service from "../../services/index";

const MasterData = {
    namespaced: true,
    state: {
        province: null,
        district: null,
        subDistrict: null,
        provinceOther: null,
        districtOther: null,
        subDistrictOther: null,
        diagnosis: null,
        hospcode: null,
        hospitalList: [],
        form1: {
            nationality: [],
            welfare: [],
            welfareNow: [],
            education: [],
            occupation: [],
            marriageStatus: [],
            relationStatus: [],
        },
        form2: {
            familyRelation: [],
            place: []
        }
    },
    mutations: {
        SET_PROVINCE: (state, data) => {
            state.province = data
        },
        SET_DISTRICT: (state, data) => {
            state.district = data
        },
        SET_SUB_DISTRICT: (state, data) => {
            state.subDistrict = data
        },
        SET_PROVINCE_OTHER: (state, data) => {
            state.provinceOther = data
        },
        SET_DISTRICT_OTHER: (state, data) => {
            state.districtOther = data
        },
        SET_SUB_DISTRICT_OTHER: (state, data) => {
            state.subDistrictOther = data
        },
        SET_DIAGNOSIS: (state, data) => {
            state.diagnosis = data
        },
        SET_HOSPCODE: (state, data) => {
            state.hospcode = data
        },
        SET_HOSPITAL_LIST: (state, data) => {
            state.hospitalList = data
        },
        SET_FORM1: (state, data) => {
            state.form1 = data
        },
        SET_FORM2: (state, data) => {
            state.form2 = data
        },
    },
    actions: {
        setForm2({commit}) {
            return Service.MasterData.getForm2().then((response) => {
                commit("SET_FORM2", response.data);
            })
        },
        setForm1({commit}) {
            return Service.MasterData.getForm1().then((response) => {
                commit("SET_FORM1", response.data);
            })
        },
        setProvince({commit}) {
            return Service.MasterData.getProvince().then((response) => {
                commit("SET_PROVINCE", response.data);
                commit("SET_PROVINCE_OTHER", response.data);
                return response;
            })
        },
        setProvinceByGovernmentCode({commit}, code) {
            return Service.MasterData.getProvinceByGovermentCode(code).then((response) => {
                commit("SET_PROVINCE", response.data);
            })
        },
        setProvinceByAreaCode({commit}, code) {
            return Service.MasterData.getProvinceByAreaCode(code).then((response) => {
                commit("SET_PROVINCE", response.data);
            })
        },
        setDistrict({commit}, id) {
            return Service.MasterData.getDistrict(id).then((response) => {
                commit("SET_DISTRICT", response.data);
                return response
            })
        },
        setSubDistrict({commit}, id) {
            return Service.MasterData.getSubDistrict(id).then((response) => {
                commit("SET_SUB_DISTRICT", response.data);
            })
        },
        setHospCode({commit}, model) {
            return Service.MasterData.getHospCodeReport(model).then((response) => {
                commit("SET_HOSPCODE", response.data);
                return response;
            })
        },
        sethospitalTypecode({commit}, model) {
            return Service.MasterData.getHospCodeReport(model).then((response) => {
                commit("SET_HOSPCODE", response.data);
                return response;
            })
        },
        setProvinceOther({commit}) {
            return Service.MasterData.getProvince().then((response) => {
                commit("SET_PROVINCE_OTHER", response.data);
            })
        },
        setDistrictOther({commit}, id) {
            return Service.MasterData.getDistrict(id).then((response) => {
                commit("SET_DISTRICT_OTHER", response.data);
            })
        },
        setSubDistrictOther({commit}, id) {
            return Service.MasterData.getSubDistrict(id).then((response) => {
                commit("SET_SUB_DISTRICT_OTHER", response.data);
            })
        },
        setDiagnosis({commit}, code) {
            return Service.MasterData.getDiagnosis(code).then((response) => {
                commit("SET_DIAGNOSIS", response.data);
            })
        },
        searchHospCode({commit}, keyword) {
            return Service.MasterData.searchHospCode(keyword).then((response) => {
                commit("SET_HOSPITAL_LIST", response.data);
                return response;
            })
        },
    }
};

export default MasterData;
