import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

import auth from './modules/authModule';
import DropDown from "./modules/DropDownModule"
import MasterData from "./modules/MasterDataModule"
import Violence from "./modules/violenceModule"
import Pregnant from "./modules/pregnantModule"
import Form from "./modules/FormModule"
import ReportSummary from "./modules/ReportSummaryModule"
import dashboard from "./modules/dashboardModule";
import dynamic from "./modules/dynamicModule";
import datauser from "./modules/DatauserModule";
const modules = {
  Auth: auth,
  DropDown,
  MasterData,
  Violence,
  Pregnant,
  Form,
  ReportSummary,
  dashboard,
  dynamic,
  datauser
}

export default new Vuex.Store({
  modules,
  plugins: [createPersistedState()],
})
