import Service from "../service";
// import AuthService from "../authService";

const Dashboard = {
    chart_1_1(year) {
        return Service.get("/dashboard/1_1?year="+year);
    },
    chart_1_2(year) {
        return Service.get("/dashboard/1_2?year="+year);
    },
    chart_1_3(year) {
        return Service.get("/dashboard/1_3?year="+year);
    },
    chart_1_4(year) {
        return Service.get("/dashboard/1_4?year="+year);
    },
    chart_2_1(year) {
        return Service.get("/dashboard/2_1?year="+year);
    },
    chart_2_2(year) {
        return Service.get("/dashboard/2_2?year="+year);
    },
    chart_2_3(year) {
        return Service.get("/dashboard/2_3?year="+year);
    },
    chart_2_4(year) {
        return Service.get("/dashboard/2_4?year="+year);
    },
    map_1(data) {
        return Service.get("/dashboard/map1?year="+data.year+'&area='+data.area+'&province='+data.province);
    },
    map_province_1(data) {
        return Service.get("/map_province_1?year="+data.year+'&province='+data.province);
    },
    map_2(data) {
        return Service.get("/dashboard/map2?year="+data.year+'&area='+data.area+'&province='+data.province);
    },
    map_province_2(data) {
        return Service.get("/map_province_2?year="+data.year+'&province='+data.province);
    },
    get_year_violence() {
        return Service.get("/dashboard/get_year_violence");
    },
    get_year_pregnant() {
        return Service.get("/dashboard/get_year_pregnant");
    },
    get_summary_recoder(data) {
        return Service.get(`/summary_recorder?area=${data.area}&province=${data.province}&district=${data.district}&hospital=${data.hospital}&start_event_date=${data.start_event_date}&end_event_date=${data.end_event_date}&start_hospital_date=${data.start_hospital_date}&end_hospital_date=${data.end_hospital_date}`);
    },
    getExcel(data) {
        return Service.get(`/recorderExport?area=${data.area}&province=${data.province}&district=${data.district}&hospital=${data.hospital}&start_event_date=${data.start_event_date}&end_event_date=${data.end_event_date}&start_hospital_date=${data.start_hospital_date}&end_hospital_date=${data.end_hospital_date}`);
    },
};

export default Dashboard;
