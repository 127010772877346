import Service from "../service";
// import AuthService from "../authService";

const Common = {
    getData() {
        return Service.get("/data-announcement");
    },
    getLog(date) {
        return Service.get(`/log-user?limit=${date.limit}&page=${date.page}&start_event_date=${date.start_event_date?date.start_event_date:""}&end_event_date=${date.end_event_date?date.end_event_date:""}&event_name=${date.event_name?date.event_name:""}`)
    }
};

export default Common;
