import Service from "../../services/index";

const Auth = {
    namespaced: true,
    state: {
        user: null,
        accessToken: null,
        registerToken : null,
    },
    mutations: {
        SET_TOKEN: (state, data) => {
            if (data){
                state.user = data.data.user;
                state.accessToken = data.data.token;
            }else{
                state.user = null;
                state.accessToken = null;
            }
        },
        SET_USER: (state, user) => {
            state.user = user;
        },
        SET_TOKEN_REGISTER : (state, token) => {
            state.registerToken = token;
        },
    },
    actions: {
        login({ commit }, data) {
            return Service.auth.login(data).then(data => {
                if (data.message == 'เข้าสู่ระบบสำเร็จ'){
                    commit("SET_TOKEN", data);
                }
                return data;
            });
        },
        register({ commit }, data) {
            return Service.auth.register(data).then(data => {
                if (data.data.token){
                    commit("SET_TOKEN_REGISTER", data.data.token);
                    return data;
                }else{
                    return data;
                }
            });
        },
        // eslint-disable-next-line no-unused-vars
        rePassword({ commit }, data) {
            return Service.auth.rePassword(data).then(data => {
                return data;
            });
        },
        // eslint-disable-next-line no-unused-vars
        forgotpassword({ commit }, data) {
            return Service.auth.forgotpassword(data)
        },
        setUser({ commit }) {
            return Service.auth
                .getUser()
                .then(data => commit("SET_USER", data))
                .catch(err => Promise.reject(err));
        },
        getToken({ commit }, data) {
            return Service.auth.getToken(data).then(data => {
                commit("SET_TOKEN", data.success.token);
            }).catch(err => Promise.reject(err));

            // return Service.auth.getToken(data);
        },
        logout({ commit }) {
            return Service.auth.logout().then(()=>{
                commit("SET_USER", null);
                commit("SET_TOKEN", null);
            })
        },
        // eslint-disable-next-line no-unused-vars
        details({ commit }) {
            return Service.auth.details().then(res=>{
                commit("SET_USER", res.data);
            })
        },
        // eslint-disable-next-line no-unused-vars
        hospcode({ commit },data) {
            return Service.auth.hospcode(data)
        },
        // eslint-disable-next-line no-unused-vars
        getPathFile({commit},data){
            return Service.auth.uploadFile(data);
        },
        // eslint-disable-next-line no-unused-vars
        getPathDocument({commit},data){
            return Service.auth.uploadDocument(data);
        },
    }
};

export default Auth;
