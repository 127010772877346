import Service from "../service";

const DropDown ={
    getForm1(){
        return Service.get("master-data/form1");
    },
    getForm2(){
        return Service.get("master-data/form2");
    },
    getProvince(){
        return Service.get("/get-province");
    },
    getProvinceByAreaCode(code){
        return Service.get(`/get-province-by-area-code/${code}`);
    },
    getProvinceByGovermentCode(code){
        return Service.get(`/get-province-by-goverment-code/${code}`);
    },
    getDistrict(id){
        return Service.get(`/get-district/${id}`);
    },
    getSubDistrict(id){
        return Service.get(`/get-sub-district/${id}`);
    },
    getDiagnosis(code){
        return Service.get(`/get-diagnosis/${code}`);
    },
    getHospCodeReport(model){
        return Service.get(`/get-hospcode`,{
            params:{
                code:model.code,
                type:model.type,
                province:model.province,
                district:model.district?model.district:'',
            }
        });
    },
    searchHospCode(keyword){
        return Service.get(`/search-hospcode`,{
            params:{
                hospcode:keyword.hospcode,
                name:keyword.name,
            }
        });
    },

}
export default DropDown
