import Service from "../../services";

const datauser = {
    namespaced: true,
    state: {
        data_user_list: [],
        data_log_user: [],
        paginateFormList: {
            currentPage: 1,
            lastPage: 1,
            perPage: 1,
            total: 1,
        },
        paginateLoguser: {
            currentPage: 1,
            lastPage: 1,
            perPage: 1,
            total: 1,
        },
    },
    mutations: {
        SET_USER: (state, data) => {
            console.log(data)
            state.data_user_list = data.data
            state.paginateFormList.currentPage = data.current_page
            state.paginateFormList.lastPage = data.last_page
            state.paginateFormList.perPage = data.per_page
            state.paginateFormList.total = data.total
            state.paginateFormList.from = data.from
            state.paginateFormList.to = data.to
        },
        SET_LOG: (state, data) => {
            state.data_log_user = data.data
            state.paginateLoguser.currentPage = data.current_page
            state.paginateLoguser.lastPage = data.last_page
            state.paginateLoguser.perPage = data.per_page
            state.paginateLoguser.total = data.total
            state.paginateLoguser.from = data.from
            state.paginateLoguser.to = data.to
        }
    },
    actions: {
        getDatauser({commit}, data) {
            return Service.Datauser.get(data).then(res => {
                commit('SET_USER', res)
                console.log(res)
            });
        },
        getDatalog({commit}, data) {
            return Service.Common.getLog(data).then(res => {
                commit('SET_LOG', res)
            });
        },
        // eslint-disable-next-line no-unused-vars
        getExcel({commit}, data) {
            return Service.Datauser.getExcel(data)
        },
        // eslint-disable-next-line no-unused-vars
        getformExcel({commit}, data) {
            return Service.Datauser.getformExcel(data)
        },
    }
}
export default datauser;