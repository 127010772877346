import Service from "../service";
import AuthService from "../authService";

const Auth = {
    login(data) {
        return Service.post("/login", data);
    },
    register(data){
        return Service.post('/register',data);
    },
    forgotpassword(data){
        return Service.post('/Forgotpassword',data);
    },
    rePassword(data){
        return Service.post('/re_password',data);
    },
    getUser() {
        return AuthService.get("/details");
    },
    logout(){
        return AuthService.get("/logout");
    },
    details(){
        return AuthService.get("/details");
    },
    getToken(data) {
        return Service.post("/get-token", data);
    },
    uploadFile(data){
        return Service.post('/upload_image',data);
    },
    uploadDocument(data){
        return Service.post('/upload_file',data);
    },
    hospcode(data){
        return Service.post('/hospcode',data);
    },
};

export default Auth;
