import Service from "../../services/index";

const dashboard = {
    namespaced: true,
    state:{
        formList:[]
    },
    mutations:{

    },
    actions:{
        // eslint-disable-next-line no-unused-vars
        chart_1_1({commit},year){
            return Service.Dashboard.chart_1_1(year);
        },
        // eslint-disable-next-line no-unused-vars
        chart_1_2({commit},year){
            return Service.Dashboard.chart_1_2(year);
        },
        // eslint-disable-next-line no-unused-vars
        chart_1_3({commit},year){
            return Service.Dashboard.chart_1_3(year);
        },
        // eslint-disable-next-line no-unused-vars
        chart_1_4({commit},year){
            return Service.Dashboard.chart_1_4(year);
        },
        // eslint-disable-next-line no-unused-vars
        chart_2_1({commit},year){
            return Service.Dashboard.chart_2_1(year);
        },
        // eslint-disable-next-line no-unused-vars
        chart_2_2({commit},year){
            return Service.Dashboard.chart_2_2(year);
        },
        // eslint-disable-next-line no-unused-vars
        chart_2_3({commit},year){
            return Service.Dashboard.chart_2_3(year);
        },
        // eslint-disable-next-line no-unused-vars
        chart_2_4({commit},year){
            return Service.Dashboard.chart_2_4(year);
        },
        // eslint-disable-next-line no-unused-vars
        map_1({commit},data){
            return Service.Dashboard.map_1(data);
        },
        // eslint-disable-next-line no-unused-vars
        map_province_1({commit},data){
            return Service.Dashboard.map_province_1(data);
        },
        // eslint-disable-next-line no-unused-vars
        map_2({commit},data){
            return Service.Dashboard.map_2(data);
        },
        // eslint-disable-next-line no-unused-vars
        map_province_2({commit},data){
            return Service.Dashboard.map_province_2(data);
        },
        // eslint-disable-next-line no-unused-vars
        get_year_violence({commit},year){
            return Service.Dashboard.get_year_violence(year);
        },
        // eslint-disable-next-line no-unused-vars
        get_year_pregnant({commit},year){
            return Service.Dashboard.get_year_pregnant(year);
        },
        // eslint-disable-next-line no-unused-vars
        get_summary_recoder({commit},data){
            return Service.Dashboard.get_summary_recoder(data);
        },
        // eslint-disable-next-line no-unused-vars
        getExcel({commit},data){
            return Service.Dashboard.getExcel(data);
        }
    }
}

export default dashboard;
