import Service from "../../services/index";

const violence = {
    namespaced: true,
    state: {
        form: null,
        form2: null,
        form3: null,
        injury_form4: [],
        form5: null,
        oscc_code: null,
        selected_sex: 'ชาย',
    },
    mutations: {
        SET_FORM1: (state, data) => {
            state.form = data
        },
        SET_FORM2: (state, data) => {
            state.form2 = data
        },
        SET_FORM3: (state, data) => {
            state.form3 = data
        },
        SET_FORM5: (state, data) => {
            state.form5 = data
        },
        SET_FORM4 : (state, data) => {
            state.injury_form4 = data.formFour;
            state.oscc_code = data.oscc_code;
            state.selected_sex = data.sex;
        },
        ADD_INJURY: (state, data) => {
            state.injury_form4.push(data);
        },
        REMOVE_INJURY: (state, data) => {
            state.injury_form4.splice(data, 1);
        },
        CLEAR_INJURY: (state) => {
            state.injury_form4 = [];
        }
    },
    actions: {
        showForm1({ commit }, id) {
            return Service.Violence1.show(id).then((response) => {
                commit("SET_FORM1", response.data);
                return response.data;
            })
        },
        showForm2({ commit }, id) {
            return Service.Violence2.show(id).then((response) => {
                commit("SET_FORM2", response.data);
                return response.data;
            })
        },
        showForm3({ commit }, id) {
            return Service.Violence3.show(id).then((response) => {
                commit("SET_FORM3", response.data);
                return response.data;
            })
        },
        showForm4({ commit }, id) {
            return Service.Violence4.show(id).then((response) => {
                commit("SET_FORM4", response.data);
                return response
            })
        },
        showForm5({ commit }, id) {
            return Service.Violence5.show(id).then((response) => {
                commit("SET_FORM5", response.data);
                return response.data;
            })
        },
        saveForm({ commit }, data) {
            return Service.Violence1.saveForm(data).then((response) => {
                commit("SET_FORM1", response.data);
                return response;
            })
        },
        saveForm2({ commit }, data) {
            return Service.Violence2.saveForm(data).then((response) => {
                commit("SET_FORM2", response.data);
                return response;
            })
        },
        saveForm3({ commit }, data) {
            return Service.Violence3.saveForm(data).then((response) => {
                commit("SET_FORM3", response.data);
                return response;
            })
        },
        saveForm5({ commit }, data) {
            return Service.Violence5.saveForm(data).then((response) => {
                commit("SET_FORM5", response.data);
                return response;
            })
        },
        saveForm4({commit},data){
            return Service.Violence4.saveForm(data).then((response)=>{
                commit("SET_FORM4", response.data);
                return response;
            })
        },
        addList({ commit }, data) {
            commit("ADD_INJURY", data);
        },
        deleteList({ commit }, data) {
            commit("REMOVE_INJURY", data);
        },
        clearList({ commit }) {
            commit("CLEAR_INJURY");
        }
    }
};

export default violence;
