<template>
  <div>
    <div class="bg-main-theme py-3 px-3">
      <div class="d-flex justify-content-between">
        <div><img :src="logo" alt="logo" /></div>
        <b-dropdown
          split
          right
          v-if="user"
          split-variant="primary"
          variant="primary"
          class="m-2"
        >
          <template #button-content>
            <span style="color: white">
              {{ user.title + " " + user.full_name }}<br />
              {{ user.agency_name }}
            </span>
          </template>
          <b-dropdown-item @click="details()">
            <b-img
              width="20%"
              height="20%"
              :src="require('@/assets/icon/info.svg')"
            ></b-img
            ><span class="ml-4">ข้อมูลผู้ใช้</span>
          </b-dropdown-item>
          <b-dropdown-item @click="Logout">
            <b-img
              width="20%"
              height="20%"
              :src="require('@/assets/icon/logout.svg')"
            ></b-img
            ><span class="ml-4">ออกจากระบบ</span>
          </b-dropdown-item>
        </b-dropdown>
        <button
          class="btn profile-btn pl-4 pr-4"
          @click="showLoginModal()"
          v-else
        >
          <small class="text-left">เข้าสู่ระบบ</small>
        </button>
      </div>
    </div>
    <MenuBar v-if="user"></MenuBar>
    <b-modal
      hide-header
      hide-footer
      size="lg"
      ref="login_modal"
      body-class="p-0"
    >
      <login-modal
        @regis="gotoRegister()"
        @login="Login"
        @forgot="gotoForgot()"
      >
      </login-modal>
    </b-modal>
  </div>
</template>

<script>
import logo from "../../assets/logo.png";
import LoginModal from "../Modal/loginModal";
import MenuBar from "./MenuBar.vue";
import { mapState } from "vuex";
export default {
  components: { LoginModal, MenuBar },
  data() {
    return {
      logo: logo,
    };
  },
  computed: {
    ...mapState({
      user: (state) => {
        return state.Auth.user;
      },
    }),
  },
  methods: {
    showLoading() {
      this.$loading.show({
        background: this.$swal.fire({
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        }),
        animation: true,
      });
    },
    hideLoading() {
      this.$loading.hide();
      this.$swal.hideLoading();
    },
    showLoginModal() {
      this.$refs.login_modal.show();
    },
    hideLoginModal() {
      this.$refs.login_modal.hide();
    },
    details() {
      this.$store.dispatch("Auth/details").then(() => {
        this.$router.push("/details-user");
      });
    },
    Login(val) {
      this.showLoading();
      this.$store
        .dispatch("Auth/login", val)
        .then((res) => {
          if (res.message == "เข้าสู่ระบบสำเร็จ") {
            if (this.$route.path !== "/") {
              this.$swal.fire({
                position: "top-end",
                icon: "success",
                title: "เข้าสู่ระบบสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.push("/");
            } else {
              this.$swal.fire({
                position: "top-end",
                icon: "success",
                title: "เข้าสู่ระบบสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              });
              window.location.reload();
            }
          } else {
            this.hideLoginModal();
            this.$swal.fire({
              icon: "error",
              title: res.message,
            });
          }
        })
        .catch(() => {
          this.hideLoginModal();
          this.$swal.fire({
            icon: "error",
            title: "กรุณาตรวจสอบข้อมูล อีเมลหรือรหัสผ่านไม่ถูกต้อง",
          });
        });
    },
    Logout() {
      this.$store.dispatch("Auth/logout").then(() => {
        if (this.$route.path !== "/") {
          this.$router.push("/");
        } else {
          window.location.reload();
        }
      });
    },
    gotoRegister() {
      this.hideLoginModal();
      if (this.$route.path !== "/register") {
        this.$router.push({ name: "register-form" });
      }
    },
    gotoForgot() {
      this.hideLoginModal();
      if (this.$route.path !== "/forgot-password") {
        this.$router.push({ name: "ForgotPassword" });
      }
    },
  },
};
</script>

<style>
.dropdown-toggle.btn-primary.dropdown-toggle-split {
  color: white !important;
}
.dropdown-menu.dropdown-menu-right.show {
  padding-left: 0 !important;
}
</style>
