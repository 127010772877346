<template>
  <div class="bg-dark-theme py-3 text-center text-white">
    <p>
      ลิขสิทธิ์ โดย สำนักบริหารการสาธารณสุข ปี พ.ศ.2553<br />
      ทำการพัฒนาโดย สำนักบริหารการสาธารณสุขเวอร์ชั่นซอร์ฟแวร์ 2553/1
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>